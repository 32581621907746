import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import PortfolioV1v from './section-components/portfolio-v1v';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const Portfolio_V1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Videolar" subheader="Videolar" />
        <PortfolioV1v />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Portfolio_V1

