import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class PortfolioV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__gallery-area mb-120">
				<div className="container">
					{/* (ltn__gallery-info-hide) Class for 'ltn__gallery-item-info' not showing */}
					<div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
						<div className="ltn__gallery-sizer col-1" />
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g1.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g1.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>  </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g2.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g2.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g3.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g3.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g4.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g4.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g5.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g5.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g6.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g6.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g7.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g7.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g18.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g18.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g9.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g9.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g19.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g19.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g11.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g11.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g11.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g17.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g12.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g12.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g13.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g13.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g14.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g14.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g15.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g15.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g16.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g16.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/about.jpg"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/about.jpg"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g20.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g20.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g21.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g21.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g22.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g22.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g23.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g23.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/g24.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/g24.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d1.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d1.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d2.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d2.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d3.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d3.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d4.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d4.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d5.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d5.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d6.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d6.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d7.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d7.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d8.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d8.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d9.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d9.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d10.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d10.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/tektekstilmedia/d11.png"} data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/tektekstilmedia/d11.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-search" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details"> Tek Tekstil  &amp;    Laminasyon </Link></h4>
							<p>   &amp;   </p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						{/* gallery-item */}
						{/* gallery-item */}
						{/* gallery-item */}
						{/* gallery-item */}
					</div>
					
					</div>
			</div>
        }
}

export default PortfolioV1