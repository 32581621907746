import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class ProductSliderV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div
        className="ltn__search-by-place-area before-bg-top bg-image-top--- pt-115 pb-70"
        data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center---">
                <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                  TekTekstil
                </h6>
                <h1 className="section-title">
                  TekTekstil: Bulunduğumuz Sektörler <br />
                </h1>
              </div>
            </div>
          </div>
          <div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
            <div className="col-lg-4">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <img src={publicUrl + "assets/img/tektekstilmedia/ayakkabi.png"} alt="#" />
                </div>
                <div className="search-by-place-info">
                
                  <h4>Ayakkabı</h4>
                  <p>
                     
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <img src={publicUrl + "assets/img/tektekstilmedia/saraciye.png"} alt="#" />
                </div>
                <div className="search-by-place-info">
                 
                  <h4>Saraciye</h4>
                  <p>
                    
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <img src={publicUrl + "assets/img/tektekstilmedia/mobilya.png"} alt="#" />
                </div>
                <div className="search-by-place-info">
                  
                  <h4>Mobilya</h4>
                  <p>
                  
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <img src={publicUrl + "assets/img/tektekstilmedia/ozel_uretim.jpg"} alt="#" />
                </div>
                <div className="search-by-place-info">
                  
                  <h4>Sektöre özel üretim</h4>
                  <p>
                   
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <img src={publicUrl + "assets/img/tektekstilmedia/terlik.png"} alt="#" />
                </div>
                <div className="search-by-place-info">
                  
                  <h4>Terlik</h4>
                  <p>
                   
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__search-by-place-item">
                <div className="search-by-place-img">
                  <img src={publicUrl + "assets/img/tektekstilmedia/otoa.jpeg"} alt="#" />
                </div>
                <div className="search-by-place-info">
                 
                  <h4>Otomotiv</h4>
                  <p>
                 
                  </p>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductSliderV2;
