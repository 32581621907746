import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-v2';
import Aboutv3 from './section-components/about-v3';
import ProductSlider from './section-components/product-slider-v2';
import Cateogory from './section-components/category-v2';
import Footer from './global-components/footer';

const Home_V2 = () => {
    return <div>
        <Navbar />
        <Banner />
        <Aboutv3 />
        <ProductSlider />
        <Cateogory />
        <Footer />
    </div>
}

export default Home_V2

