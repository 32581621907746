import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV4 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/tektekstilmedia/about1.jpeg"}
                  alt="About Us Image"
                />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
                  <div className="ltn__video-img ltn__animation-pulse1"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    Hakkımızda
                  </h6>
                  <h1 className="section-title">
                    TekTekstil ve Laminasyon<span>.</span>
                  </h1>
                  <p>
                    Tektekstil, tekstil sektöründe geniş bir yelpazede faaliyet
                    göstermektedir. Dokuma kumaş, dijital baskı, UV baskı ve
                    laminasyon konularındaki uzmanlığımızla, ayakkabı, saraciye,
                    terlik, mobilya, otomotiv ve özel sektörlerde kaliteli
                    ürünler sunmaktayız. İthalat alanındaki güçlü ağımızla,
                    dünya genelinde en iyi ve yenilikçi malzemeleri
                    müşterilerimize ulaştırıyoruz.
                  </p>
                </div>
                
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>"Sorularız için Whatsapp ile ulaşabilirsiniz." </p>
                </div>
                <div className="btn-wrapper animated">
                  <a style={{backgroundColor:'green'}}
                    href="https://api.whatsapp.com/send?phone=905343439380"
                    className="theme-btn-1 btn btn-effect-1"
                  >
                    Whatsapp Hattımız
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV4;
