import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CategoryV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-70">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color"></h6>
			          <h1 className="section-title">Hizmetlerimiz</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center go-top">
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/">
			            <span className="category-icon">   <img src="ico/woven.png" alt="Custom Icon 1" />  </span>
			            <span className="category-number">1</span>
			            <span className="category-title">Dokuma Kumas</span>
			            <span className="category-brief">
			            Müşterilerimize özel tasarımlar ve fonksiyonel özellikler sunuyoruz.
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/">
			            <span className="category-icon">  <img src="ico/technology.png" alt="Custom Icon 1" /></span>
			            <span className="category-number">2</span>
			            <span className="category-title">Dijital Baski</span>
			            <span className="category-brief">
			            Yenilikçi dijital baskı teknolojileri ile özgün tasarımlar sağlıyoruz.
			            </span>
			            <span className="category-btn d-none">  </span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/">
			            <span className="category-icon">  <img src="ico/uv-protection.png" alt="Custom Icon 1" /></span>
			            <span className="category-number">3</span>
			            <span className="category-title">UV Baski</span>
			            <span className="category-brief">
			            UV baskı teknolojisi ile dayanıklı ve canlı renklere sahip ürünler üretiyoruz.
			            </span>
			            <span className="category-btn d-none"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      
			      
			      <div className="col-lg-3 col-md-4 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
			          <Link to="/">
			            <span className="category-icon">  <img src="ico/laminator.png" alt="Custom Icon 1" /></span>
			            <span className="category-number">4</span>
			            <span className="category-title">Laminasyon</span>
			            <span className="category-brief">
						Laminasyon işlemleri ile ürünlerimizi ekstra koruma ve dayanıklılık katıyoruz.
			            </span>
			            <span className="category-btn d-none">  <img src="ico/laminator.png" alt="Custom Icon 1" /></span>
			          </Link>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default CategoryV2