import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class PortfolioV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__gallery-area mb-120">
				<div className="container">
					{/* (ltn__gallery-info-hide) Class for 'ltn__gallery-item-info' not showing */}
					<div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
						<div className="ltn__gallery-sizer col-1" />
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/1.mp4"} data-rel="lightcase:myCollection">
							<img src={publicUrl+"assets/img/2.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-video" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details">Videolarımız </Link></h4>
							<p> &amp;</p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/2.mp4"} data-rel="lightcase:myCollection">
							<img src={publicUrl+"assets/img/3.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-video" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details">Videolarımız </Link></h4>
							<p> &amp;</p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/3.mp4"} data-rel="lightcase:myCollection">
							<img src={publicUrl+"assets/img/4.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-video" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details">Videolarımız </Link></h4>
							<p> &amp;</p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/4.mp4"} data-rel="lightcase:myCollection">
							<img src={publicUrl+"assets/img/5.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-video" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details">Videolarımız </Link></h4>
							<p> &amp;</p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						<div className="ltn__gallery-item filter_category_2 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href={publicUrl+"assets/img/5.mp4"} data-rel="lightcase:myCollection">
							<img src={publicUrl+"assets/img/2.png"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-video" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top"><Link to="/portfolio-details">Videolarımız </Link></h4>
							<p> &amp;</p>
							</div>
						</div>
						</div>
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						

						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						
						{/* gallery-item */}
						{/* gallery-item */}
						{/* gallery-item */}
						{/* gallery-item */}
						{/* gallery-item */}
					</div>
					
					</div>
			</div>
        }
}

export default PortfolioV1