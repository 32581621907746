import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-115 pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img
                  src={publicUrl + "assets/img/tektekstilmedia/about.jpg"}
                  alt="About Us Image"
                />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
                  <div className="ltn__video-img ltn__animation-pulse1">
                    <img
                      src={publicUrl + "assets/img/5.png"}
                      alt="video popup bg image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-30">
                  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                    Hakkımızda
                  </h6>
                  <h1 className="section-title">
                    Tektekstil - 2007'den Beri Sektörün Lideri!{" "}
                  </h1>
                  <p>
                    Şirketimiz, dokuma kumaş, dijital baskı, UV baskı ve
                    laminasyon alanlarında geniş bir ürün yelpazesi sunarak,
                    müşterilerine kaliteli ve özgün çözümler sağlamaktadır.
                    İthalat konusundaki uzmanlığımızla sektörde güvenilir bir iş
                    ortağı olarak biliniriz.
                  </p>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-house-4" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a>Doğa Dostu Ürünler</a>
                    </h4>
                    <p>
                      Ürünlerimizi hijyenik ortamlarda üretmekteyiz.
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-call-center-agent" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a></a>Destek Hattımız
                    </h4>
                    <p>Sizin için destek hattımız her daim yanınızda.</p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-maps-and-location" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a>Konum</a>
                    </h4>
                    <p>
                    İkitelli Organize Sanayi Bölgesinde hizmetinizdeyiz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV3;
