import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";

import HomeV2 from './components/home-v2';
import About from './components/about';
import Portfolio from './components/portfolio';
import Portfoliov from './components/portfoliov';
import Contact from './components/contact';




class Root extends Component {
    render() {
        return(
                <HashRouter basename="/">
	                <div>
	                <Switch>
	                    <Route exact path="/" component={HomeV2} />
                        <Route path="/about" component={About} />
                        <Route path="/portfolio" component={ Portfolio } />
                        <Route path="/portfoliov" component={ Portfoliov } />
                        <Route path="/contact" component={ Contact } />
	                </Switch>
	                </div>
                </HashRouter>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('quarter'));
