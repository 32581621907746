import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContactInfo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
				<div className="row">
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/10.png"} alt="Icon Image" />
						</div>
						<h3>Email Adresimiz</h3>
						<p>info@teklaminasyon.com<br /><br /> 
						
						</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/11.png"} alt="Icon Image" />
						</div>
						<h3>Telefon Numarası</h3>
						<p>+90 534 343 93 80 <br /> <br /> </p>
						<div className="btn-wrapper animated">
                  <a href="https://api.whatsapp.com/send?phone=905343439380" className="theme-btn-1 btn btn-effect-1">
                    Whatsapp İletişim
                  </a>
                </div>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<div className="ltn__contact-address-icon">
						<img src={publicUrl+"assets/img/icons/12.png"} alt="Icon Image" />
						</div>
						<h3>Konumumuz</h3>
						<p> Saraçlar 2. Blok Sk No:119, 34490 İkitelli Osb/Başakşehir/İstanbul</p>
						<div className="btn-wrapper animated">
                  <a href="https://www.google.com/maps?q=41.08628845214844,28.786287307739258&z=17&hl=tr" className="theme-btn-1 btn btn-effect-1">
                    Konum
                  </a>
                </div>
					</div>
					</div>
				</div>
				</div>
			</div>
        }
}

export default ContactInfo