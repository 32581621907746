import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import Map from './section-components/map';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const ContactV1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Bize Ulaşın" subheader="Bize Ulaşın" />
        <ContactInfo />
      
        
        <Footer />
    </div>
}

export default ContactV1

