import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import AboutV4 from './section-components/about-v4';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const About_v1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Hakkımızda" />
        <AboutV4 />
        
        <Footer />
    </div>
}

export default About_v1

